$color_blue: #36b3ed;
$txt_color: #686868;
$txt_2: #505050;
$banner_txt: #3C3C3C;
$banner_blue: #009FE8;
$gray_bg: #ebebeb;

@mixin size($w,$h) {
  width: $w;
  height: $h; }

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin t($t) {
  transition-duration: $t; }



.cus-index-banner {
  .swiper-pagination.index {
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    .swiper-pagination-bullet {
      margin: 0 4px;
      width: 9px;
      height: 9px;
      background: #e0e0e0;
      opacity: 1;
      outline: none; }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $color_blue; } } }

@media screen and (max-width: 767px) {
  .cus-index-banner {
    .swiper-pagination.index {
      bottom: 10px;
      .swiper-pagination-bullet {
        width: 7px;
        height: 7px; } } } }

.cus-index-digital {
  box-shadow: 0px 0px 10px rgba(#000, .4);
  .cus-col-7, .cus-col-5 {
    padding-left: 0;
    padding-right: 0; }
  .cus-col-7 {
    padding-left: 20px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 25px 0 0;
      border-color: $color_blue transparent transparent transparent; } }

  .num {
    margin-top: 10px;
    color: $color_blue;
    font-size: 58px; }
  .digital-info-1 {
    padding-top: 35px;
    padding-bottom: 15px;
    .cus-col-4 {
      display: flex;
      .title, .unit {
        font-size: 24px; }
      .unit {
        position: relative;
        align-self: flex-end;
        padding-left: 20px;
        margin-top: 20px;
        &::before {
          content: '';
          display: block;
          background: #b8b8b8;
          height: 1px;
          width: 60px;
          transform: rotate(-45deg);
          position: absolute;
          right: 0px;
          bottom: 25px; } } } }

  .digital-info-2 {
    .cus-col-4 {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border-left: 1px solid #b5b5b5;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 25px 0 0;
        border-color: $color_blue transparent transparent transparent; } }
    .unit {
      text-align: right;
      margin-top: 1px; } } }

@media screen and (max-width: 991px) {
  .cus-index-digital {
    .cus-col-7, .cus-col-5 {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      .cus-col-4 {
        br {
          display: none; } } }
    .cus-col-5 {
      border-left: 1px solid #b5b5b5; }
    .num {
      font-size: 22px; }
    .digital-info-1 {
      padding-top: 10px;
      border-bottom: 1px solid #b5b5b5;
      .cus-col-4 {
        flex-direction: column;
        align-items: center;
        .title {
          word-break: keep-all; }
        .title, .unit {
          font-size: 12px; }
        .unit {
          padding-left: 0px;
          padding-right: 0px;
          margin-top: 5px;
          align-self: center;
          font-size: 12px;
          &::before {
            display: none; } } } }
    .digital-info-2 {
      .cus-col-4 {
        border-left: none;
        &::after {
          left: 0px; } }
      .unit {
        text-align: center; }
      .title {
        font-size: 12px;
        word-break: keep-all; } } } }

.cus-index-leader {
  padding-top: 35px;
  padding-bottom: 75px;
  position: relative;



  img {
    width: 100%; }
  .swiper-container.leader {
    padding-top: 20px;
    padding-bottom: 20px;
    .swiper-slide {
      padding: 10px;
      box-shadow: 2px 2px 10px rgba(#000, .5);
      background: #fff;
      transform: scale(1) translateY(100px);
      transition: 1s;
      opacity: 0;
      &.swiper-slide-active {
        transform: scale(1.05) translateY(100px);
        &.active {
          transform: scale(1.05) translateY(0px);
          opacity: 1; } }
      &.active {
        transform: scale(1) translateY(0px);
        opacity: 1; } } }
  .swiper-arrow-group {
    bottom: 40px; }
  .txt {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    > * {
      text-align: center; } }
  h3.title {
    font-size: 21px;
    color: #080808;
    letter-spacing: 3px; }
  p {
    font-size: 16px;
    letter-spacing: 2px; } }

@media screen and (max-width: 767px) {
  .cus-index-leader {
    .common-title {
      flex-direction: column;
      h2.title {
        margin-bottom: 5px; }
      i {
        display: none; } }
    .swiper-container.leader {
      .swiper-slide {
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        &.swiper-slide-active {
          transform: scale(1); } } }
    h3.title {
      font-size: 16px;
      text-align: center; }
    p {
      font-size: 14px;
      text-align: center; } } }

#cus-index-news {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #ebebeb;
  * {
    float: none;
    font-size: 16px; }
  .common-title.slash {
    h2.title {
      font-size: 30px; } }

  .list-post-type .list-item {
    figure, .text {
      display: none; } }
  .kc-col-container {
    width: 80%;
    max-width: 1400px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px; }

  .list-post-type .list-item {
    position: relative;
    padding: 0;
    margin: 0;
    border-bottom: none;
    margin-bottom: 18px;
    border: 1px solid $color_blue;
    transform: translateY(100px);
    transition: .6s;
    &.active {
      transform: translateY(0); }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background: #6cd7f3;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      transition: .3s linear;
      z-index: 1;
      opacity: 0; }
    &:hover {
      &::after {
        right: -4px;
        bottom: -4px;
        opacity: 1; } }
    i {
      display: none; } }

  .index-news-top-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    .common-title {
      margin-bottom: 10px; }

    .news-nav {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          position: relative;
          padding: 0 10px;
          margin-left: 15px;
          margin-right: 15px;
          cursor: pointer;
          &::after {
            position: absolute;
            left: 50%;
            bottom: -5px;
            content: "";
            display: block;
            width: calc(100% + 14px);
            height: 2px;
            background-color: $color_blue;
            transform: translate(-50%, 0) rotateY(90deg);
            transition: transform 0.25s ease; }
          &.active {
            color: $color_blue; }
          &:hover, &.active {
            &::after {
              transform: translate(-50%, 0) rotateY(0deg); } } } } } }


  .cus-index-news {
    margin-top: 20px; }

  .post-content {
    position: relative;
    display: flex;
    // justify-content: center
    align-items: center;
    padding-right: 40px;
    z-index: 100;
    background: #fff;
    .kc-entry_meta {
      display: block;
      width: auto;
      // display: flex
      padding-bottom: 0;
      margin-right: 40px;
      @media (max-width: 767px) {
        margin-right: 0; }
      > .date {
        width: 0; }
      > .categories-links {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
        margin-right: 0;
        text-align: center;
        color: #000;
        font-size: 0;
        // white-space: nowrap
        a {
          display: block;
          &:first-of-type {
            display: none; }
          &:last-of-type {
            display: block; } } }
      > .date {
        margin-right: 0;
        time {
          line-height: 1; } }

      > .categories-links {
        color: $color_blue;
        background: $color_blue;
        display: block;
        order: -1;
        a {
          color: #fff;
          line-height: 1; } } }

    > h3 {
      order: 1;
      width: calc(65% - 150px);
      margin-bottom: 0;
      line-height: 1.7;
      @media (max-width: 767px) {
        width: 100%; } } } }

#cus-index-news .post-content .kc-entry_meta>.date time {
  position: absolute;
  right: 40px;
  bottom: 50%;
  transform: translateY(50%);
  border-left: solid 1px #ccc;
  padding-left: 30px;
  padding-bottom: 7px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    right: 50%;
    bottom: -25px;
    transform: translateX(50%);
    padding-left: 0;
    border: none; }
  &>span {
    font-size: 18px;
    line-height: 30px;
    color: #666;
    text-align: center; }
  .bottom {
    span {
      padding: 0 2px;
      font-size: 12px;
      color: #888; } } }

@media screen and (max-width: 767px) {
  #cus-index-news {
    .common-title.slash h2.title {
      font-size: 16px; }
    .index-news-top-area {
      .news-nav {
        ul {
          li {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 14px;
            word-break: keep-all; } } } }
    .kc-col-container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; }
    .post-content {
      flex-wrap: wrap;
      padding-right: 0;
      padding-bottom: 20px;
      .kc-entry_meta {
        width: 100%;
        flex-wrap: wrap;

        > .date, > .categories-links {
          width: 100%;
          margin-right: 0; }

        > .categories-links {
          padding-top: 15px;
          padding-bottom: 15px; }
        > .date {
          padding-top: 10px;
          padding-bottom: 10px; } }

      > h3 {
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        margin-bottom: 50px;
        &:after {
          content: "";
          display: block;
          width: 150px;
          border-bottom: solid 1px #ccc;
          margin: 10px auto 0; } } } } }



.cus-index-honer {
  padding-top: 65px;
  padding-bottom: 65px;
  background: #fff;
  .custom-container.pull-left {
    float: none;
    margin-right: auto;
    margin-left: unset;
    padding-left: 0px;
    padding-right: 15px;
    overflow: hidden;
    z-index: 100; }
  .cus-col-7 {
    padding-left: 0; }
  .cus-col-5 {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 8%; }
  .txt {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    h2.title {
      font-size: 21px;
      letter-spacing: 2px;
      line-height: 1.7;
      margin-bottom: 0px; }
    p {
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 0px;
      margin-top: 30px;
      text-align: center;
      & + p {
        margin-top: 10px; } } }
  img {
    width: 100%; }
  // .swiper-slide-active
  .swiper-slide {
    .pic {
      position: relative;
      .ani-box-index {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        i {
          display: block;
          width: 20%;
          height: 25%;
          background: #000;
          transition: -1s .3s linear;
          &:nth-child(even) {
            // background: #000
            transform-origin: right; }
          &:nth-child(odd) {}
          // background: #fff
 } } }          //transform-origin: left
    &[data-swiper-slide-index="0"] {
      .ani-box-index.active {
        i {
          transform: rotateY(90deg);
          opacity: 1;
          background: #292222;
          transition: .2s linear; } } }

    &.swiper-slide-active {
      i {
        transform: rotateY(90deg);
        opacity: 1;
        background: #fff;
        transition: .2s linear; } } }





  a.more {
    margin-top: 30px;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 2px;
    padding: 0 5px;
    border-bottom: 1px solid $color_blue;
    transition: .3s;
    &:hover {
      transform: scale(1.2); } }

  .swiper-arrow-group {
    right: 50%;
    bottom: 0px;
    left: auto;
    transform: translateX(50%);
    z-index: 100; }

  // .swiper-pagination
  //   right: 10px
  //   bottom: 10px
  //   .swiper-pagination-bullet
 }  //     margin: 0 3px

@media screen and (max-width: 767px) {
  .cus-index-honer {
    padding-bottom: 20px;
    .txt {
      h2.title {
        margin-top: 30px;
        & + h2.title {
          margin-top: 10px; } } }
    .cus-col-5 {
      justify-content: center; }
    .custom-container.pull-left {
      width: 100%;
      margin: auto;
      padding-left: 15px;
      padding-right: 15px;
      overflow: unset; }
    [class ^= 'cus-col-'] {
      padding-left: 15px;
      padding-right: 15px; }
    .txt {
      padding-bottom: 40px; }
    .swiper-arrow-group {
      bottom: 0px; }
    a.more {
      margin-top: 10px; }
    // .swiper-pagination
    //   bottom: -40px
    //   left: 50%
    //   right: auto
 } }    //   transform: translateX(-50%)

// index-courses-origin
.cus-index-courses, .cus-inner-courses {
  padding-top: 35px;
  padding-bottom: 35px;
  background: $gray_bg;
  .swiper-container.mobile-courses {
    padding-bottom: 10px;
    .swiper-pagination-bullet-active {
      background: $color_blue; } }
  img {
    width: 100%; }
  .cus-col-4 {
    margin-bottom: 30px;
    overflow: hidden;
    .box {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: #fff;
      &::after {
        content: '';
        position: absolute;
        display: block;
        background: $color_blue;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        transition: .3s linear;
        z-index: -1;
        opacity: 0; }
      .ani-box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        background-color: rgba(14, 25, 45, 0.4);
        z-index: 100;
        transition: .3s;
        .ani-txt {
          position: absolute;
          opacity: 0;
          transform: translateX(-100px);
          transition: .3s;
          transition-delay: .2s;
          width: 100%;

          p {
            color: #fff;
            text-align: center;
            // padding-left: 30px
 } } } } }            // padding-right: 30px
  .box {
    &:hover {
      // &::after
      //   right: -5px
      //   bottom: -5px
      //   opacity: 1
      .ani-box {
        opacity: 1;
        transform: translateX(0);
        transform-origin: left;
        .ani-txt {
          opacity: 1;
          transform: translateX(0); } } } }



  .pic {
    position: relative;
    z-index: 10; }

  .txt {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: relative;
    z-index: 10; }

  .tag-group {
    align-self: flex-start;
    display: flex;
    margin-bottom: 10px;
    .tag {
      // padding: 5px 10px
      span {
        color: #fff;
        letter-spacing: 1px;
        font-size: 14px;
        background: $color_blue;
        padding: 5px 10px; }
      & + .tag {
        margin-left: 5px; } } }

  .intro {
    p {
      line-height: 1.7;
      font-size: 16px;
      color: $txt_color; } } }

// index-courses-origin End

.cus-index-courses, #cus-index-news, .recruitment.button, #cus-index-courses {
  .cus-more {
    a {
      position: relative;
      display: inline-block;
      padding: 20px 70px;
      font-size: 16px;
      letter-spacing: 2px;
      background: #fff;
      border: 1px solid $color_blue;
      transition: .3s;
      z-index: 100;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $color_blue;
        z-index: -1;
        transform: rotateY(90deg);
        transition: transform .25s ease;
        transform-origin: right; }
      &:after {
        content: '';
        position: absolute;
        display: block;
        right: -32px;
        top: 50%;
        transform: translateY(-50%);
        width: 64px;
        height: 1px;
        background: $color_blue;
        transition: .3s linear; }
      &:hover {
        // background: $color_blue
        color: #fff;
        &::before {
          transform-origin: left;
          transform: rotateY(0deg); }
        &:after {
          right: -50px; } } } } }

@media screen and (max-width: 767px) {
  .cus-index-courses, #cus-index-news, .recruitment.button, #cus-index-courses {
    .cus-more {
      margin-top: 0;
      a {
        padding: 12px 35px;
        font-size: 14px; } } } }



// index-courses-new

#cus-index-courses {
  padding-top: 35px;
  padding-bottom: 35px;
  background: #ebebeb;
  .swiper-container.mobile-courses {
    .swiper-pagination-bullets {
      bottom: 0px; }
    .swiper-pagination-bullet-active {
      background: $color_blue; } }
  .kc-col-container {
    width: 90%;
    max-width: 1600px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px; } }

.cus-index-courses, .cus-inner-courses {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 0;
  a.box {
    display: block;
    overflow: hidden; }
  .list-item {
    border-bottom: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 0;
    width: calc(33.333333% - 31px);
    float: none;
    background: transparent;
    h3 {
      padding: 20px 0;
      margin-bottom: 0;
      float: none;
      background: #fff; }
    h3, h3 a {
      text-align: center;
      line-height: 1.7;
      font-size: 16px;
      color: #686868; }

    figure {
      margin-bottom: 0;
      float: none; }
    img {
      height: 300px;
      object-fit: cover;
      object-position: center center; }


    .kc-entry_meta, .text {
      display: none; } } }

.cus-inner-courses {
  padding-top: 80px;
  padding-bottom: 80px; }
@media screen and (max-width: 767px) {
  #cus-index-courses, .cus-inner-courses {
    .kc-col-container {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; }

    .cus-more {
      margin-top: 20px; } }


  .cus-index-courses {
    flex-wrap: nowrap;
    .list-item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      img {
        height: 200px; } } }

  .cus-inner-courses {
    padding-top: 40px;
    padding-bottom: 50px; } }





// index-courses-new End


.cus-index-branches {
  min-height: 1000px;
  overflow: hidden;
  padding-top: 85px;
  padding-bottom: 70px;
  background-image: url("/wp-content/uploads/index-branch-bg.png");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  img {
    width: 100%; }
  .custom-row {
    h2.title {
      font-size: 31px;
      letter-spacing: 2px;
      font-style: italic; } }
  .txt {
    position: relative;
    padding: 70px 35px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    span, a {
      font-size: 16px;
      color: $txt_color; }

    >* {
      display: flex;
      justify-content: flex-start;
      align-items: center; }

    .info-phone {
      margin-bottom: 10px; }

    .link-group {
      width: 100%;
      margin-top: 20px;
      a {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px; }
        &+a {
          margin-left: 15px; } }
      img {
        display: block;
        width: 20px; }
      span {
        display: block;
        font-size: 14px;
        border-bottom: 1px solid $txt_color; } }
    &::after {
      content: '';
      position: absolute;
      display: block;
      background: $color_blue;
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      transition: .3s linear;
      z-index: -1;
      opacity: 0; }
    &:hover {
      &::after {
        right: -15px;
        bottom: -15px;
        opacity: 1; } } }

  .cus-col-caculate-2 {
    position: relative;
    margin-top: 20%;
    width: 35%;
    margin-right: -15%;
    z-index: 100;
    > .branches-info-wrap {
      position: absolute;
      top: 0px;
      left: -100px;
      opacity: 0;
      width: 100%;
      z-index: -10;
      transition: .5s linear;
      &.active {
        left: 0;
        opacity: 1;
        z-index: 1; } } }

  .school-selector {
    margin: 10px;
    ul {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap; }
    li {
      width: 25%;
      text-align: right;
      font-size: 18px;
      color: $txt_color;
      letter-spacing: 2px;
      cursor: pointer;
      transition: .3s;
      &.active, &:hover {
        color: $color_blue; } } }

  .cus-col-caculate-10 {
    width: 80%;
    .pic-group {
      width: 100%;
      position: relative;
      .pic {
        position: absolute;
        width: 100%;
        top: 100px;
        left: 0;
        opacity: 0;
        z-index: -10;
        transition: .8s;
        &.active {
          top: 0;
          opacity: 1;
          z-index: 1; } } } } }

@media screen and (max-width: 576px) {
  .cus-index-branches {
    .txt {
      padding: 70px 10px;
      .info-address {
        align-items: start; }
      .link-group {
        img {
          width: 10px; }
        span {
          font-size: 12px; } } } } }

@media screen and (max-width: 1400px) {
  .cus-index-branches {
    min-height: 900px; } }

@media screen and (max-width: 1300px) {
  .cus-index-branches {
    min-height: 800px; } }

@media screen and (max-width: 940px) {
  .cus-index-branches {
    min-height: 700px; } }

@media screen and (max-width: 767px) {
  .cus-index-branches {
    min-height: 1050px;
    background-size: cover;
    .txt {
      span, a {
        font-size: 14px;
        letter-spacing: 1px; } }
    .cus-col-caculate-2 {
      margin-top: 65%;
      width: 100%;
      > .branches-info-wrap {
        top: 0;
        left: -100%;
        right: 15px;
        width: calc(100% - 30px);
        &.active {
          top: 0;
          left: 15px;
          opacity: 1; } } }

    .cus-col-caculate-10 {
      width: 100%;
      order: -1;
      display: flex;
      flex-direction: column;
      .pic-group {
        order: 1;
        .pic {
          top: 30px;
          left: -100%;
          &.active {
            left: 0;
            top: 30px; } } } }

    .school-selector {
      margin: 0;
      position: relative;
      width: 100%;
      z-index: 1000;
      ul {
        justify-content: flex-start; }
      li {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        width: 33.33333%; } } } }

@media screen and (max-width: 576px) {
  .cus-index-branches {
    min-height: 850px;
    .school-selector {
      li {
        font-size: 13px; } } } }

@media screen and (max-width: 380px) {
  .cus-index-branches {
    min-height: 750px; } }



// Video-Swiper
#cus-index-videos {
  position: relative;
  z-index: 100;
  padding-bottom: 80px;
  img {
    width: 100%;
    transition: .3s linear; }

  .common-title {
    margin-bottom: 0;
    padding-bottom: 40px;
    padding-top: 25px;
    background: #ebebeb; }
  .swiper-arrow-group {
    position: relative;
    margin-top: 35px;
    bottom: auto;
    left: auto;
    transform: translateX(0);
    display: flex;
    justify-content: center; }
  .swiper-slide {
    overflow: hidden;
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.active {
      transform: translateY(0px);
      opacity: 1; }
    > a {
      position: relative;
      display: block;
      .video-player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px; } }

    &:hover {
      .pic {
        img {
          transform: scale(1.1); } } } } }

@media screen and (max-width: 767px) {
  #cus-index-videos {
    padding-bottom: 20px;
    .swiper-arrow-group {
      margin-bottom: 20px; } } }




// footer

.cus-footer {
  position: relative;
  padding-top: 40px;
  background: #333;
  > * {
    color: #fff; }
  .footer-logo {
    margin-bottom: 30px;
    .pic {
      width: 270px;
      margin: auto;
      img {
        width: 100%; } } }
  .custom-container {
    @media screen and (max-width: 767px) {
      padding: 0 30px; } }

  .custom-row {
    flex-wrap: nowrap;
    justify-content: space-evenly;
    .menu {
      display: flex;
      justify-content: space-around;
      width: 100%;
      @media screen and (max-width: 767px) {
        display: block;
        max-width: 500px;
        margin: 0 auto; }
      &>li {
        margin-top: 0;
        &.menu-item-has-children>a {
          pointer-events: none; }
        &.active.menu-item-has-children>a {
          &:after {
            display: block;
            content: "-"; } }
        @media screen and (max-width: 767px) {
          display: block;
          text-align: left; }
        &>a {
          display: block;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 15px;
          text-decoration: none;
          @media screen and (max-width: 767px) {
            padding-bottom: 0; } }
        &.menu-item-has-children>a {
          @media screen and (max-width: 767px) {
            // border-bottom: solid 1px #fff
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:after {
              display: block;
              content: "+"; } } }
        ul {
          @media screen and (max-width: 767px) {
            display: none;
            padding-bottom: 20px; }
          li {
            @media screen and (max-width: 767px) {
              margin: 0;
              padding: 5px 0;
              text-align: left; }
            a {
              font-size: 14px;
              display: block; } } } } } }

  .link-wrap {
    a {
      font-size: 14px;
      transition: .3s;
      &:hover {
        color: #36b3ed !important;
        opacity: 1 !important; } }

    h4 > a {
      font-size: 16px; } }
  h4 {
    font-size: 16px;
    padding-bottom: 15px;
    text-align: center;
    letter-spacing: 1px; }

  .copyright {
    margin-top: 40px;
    padding-bottom: 20px;
    p {
      text-align: center;
      font-size: 12px; } }
  li {
    line-height: 1.5;
    margin-top: 10px;
    text-align: center;
    letter-spacing: 1px; } }


@media screen and (max-width: 767px) {
  .cus-footer {
    h4 {
      cursor: pointer;
      padding-bottom: 5px; }
    .custom-row {
      position: relative;
      flex-wrap: wrap; }
    .link-wrap {
      width: 100%;
      margin-bottom: 40px;
      padding-left: 25px;
      padding-right: 25px;
      h4 {
        text-align: left;
        padding-left: 10px; }
      li {
        text-align: left;
        padding-left: 20px;
        line-height: 1.3; }

      h4 + ul {
        display: none; }
      &.plus {
        h4 {
          position: relative;
          &::after {
            content: '+';
            position: absolute;
            right: 0; } }
        &.active {
          h4 {
            &::after {
              content: '-'; } } } } } } }





#go-top {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 11%;
  right: 5%;
  z-index: 1000;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent #fff transparent;
  cursor: pointer;
  transition: .3s linear;
  .txt {
    position: absolute;
    width: 35px;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    letter-spacing: 1px;
    padding-left: 2px; }
  &:hover {
    border-color: transparent transparent $color_blue transparent; } }

@media screen and (max-width: 767px) {
  #go-top {
    top: auto;
    bottom: 50px; } }


// footer End
